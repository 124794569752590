import React from "react";
import {ReactComponent as Logo} from '../../assets/validacao.svg';
import './index.css';

function Header() {
  return (
    <div className="header">
      <Logo className="logo" />
      <div>
        <div>
          Quality Validador
      </div>
      </div>
    </div>
  );
}

export default Header;
