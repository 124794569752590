import './index.css';
import '../../components/header'
import Header from '../../components/header';
import Detail from '../../components/fileDetail';
import {useParams} from 'react-router';
import Auth from '../..//services/firebaseAuth';
import {useState,useEffect} from 'react';
import ReactLoading from 'react-loading';
import getFile from '../../services/FirebaseDatabase';


function App() {
  // eslint-disable-next-line
  const [load,setLoad] = useState(true);
  const [start] = useState(0);
  const [data,setData] = useState({});
  const internalRef = useParams();

  const checkValidate = async () => {
    const resp = await Auth();
    if(resp) {
      // console.log('referencia',internalRef)
      const dataFile = await getFile(internalRef);
      setData(dataFile);
    };
    setLoad(!resp);
  }

  const Loading = () => {
    return (
      <ReactLoading type={'spinningBubbles'} color={'#0069A0'} height={50} width={50} />
    )
  }

  const renderResult = () => {
    return <Detail data={data} />
  }

  useEffect(() => {
    checkValidate();

  },[start]);

  return (
    < div className="App" >
      <Header />
      {load ? Loading() : renderResult()}
    </div >
  );
}

export default App;
