import {child,get,ref} from "firebase/database";
import {dbFirebase} from "./firebaseConf";



const makePath = (ref) => {
    let path = '';

    path = path + (ref.id ? ref.id : '');
    path = path + (ref.id1 ? '/' + ref.id1 : '');
    path = path + (ref.id2 ? '/' + ref.id2 : '');
    path = path + (ref.id3 ? '/' + ref.id3 : '');
    path = path + (ref.id4 ? '/' + ref.id4 : '');
    path = path + (ref.id5 ? '/' + ref.id5 : '');
    path = path + (ref.id6 ? '/' + ref.id6 : '');
    path = path + (ref.id7 ? '/' + ref.id7 : '');
    path = path + (ref.id8 ? '/' + ref.id8 : '');
    path = path + (ref.id9 ? '/' + ref.id9 : '');
    path = path + (ref.id10 ? '/' + ref.id10 : '');
    path = path + (ref.id11 ? '/' + ref.id11 : '');
    path = path + (ref.id12 ? '/' + ref.id12 : '');
     if(!path || !path === "" )
      path = '/-1'; 
  console.log(path)
    return path;
}
async function getFile(internalRef) {
    const dbref = ref(dbFirebase);

     const resp = await get(child(dbref, makePath(internalRef) )).then((snapshot) => {
        if(snapshot.exists()) {
            return snapshot.val();

        } else {
            console.log("No data available");
            return undefined
        }
    }).catch((error) => {
        console.log('E R R O R',error);
    })
    console.log(resp)
    return resp;
};

export default getFile;

