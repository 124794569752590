

import {signInWithEmailAndPassword} from "firebase/auth";
import {authFirebase} from './firebaseConf';
async function Auth() {

    try {
        
        const resp = await signInWithEmailAndPassword(authFirebase,process.env.REACT_APP_USER,process.env.REACT_APP_PASSwORD);
        // console.log('#######################',resp);

        return !!resp.user
    } catch(error) {
        console.log('$$$$$$$$$$$$$$$$',error);
    }
}

export default Auth;

