import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import { getDatabase } from "firebase/database";

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
    projectId: process.env.REACT_APP_PROJECT_ID,
};

const appFirebase = initializeApp(config);
const authFirebase = getAuth(appFirebase);
const dbFirebase = getDatabase(appFirebase);

export default appFirebase;
export {authFirebase,dbFirebase};



