import {ReactComponent as Logo} from "../../assets/validacao.svg";
import {ReactComponent as Pdf} from "../../assets/pdf.svg";
import {ReactComponent as Failure} from "../../assets/failure.svg";
import "./index.css";

export default ({data}) => {
  const fileValid = () => {
    return (
      <div className="Container">
        <div className="ContainerTop">
          <Logo className="logoValidate" />
          <div className="title">Arquivo Validado</div>
        </div>
        <div className="ContainerBottom">
          <div className="ContainerLabels">
            <div className="label">Data de Criação: </div>
            <div className="labelData">{data.DataCriacao}</div>
          </div>
          <div className="ContainerLabels">
            <div className="label"> MD5: </div>
            <div className="labelData">{data.MD5File}</div>
          </div>
          <div className="ContainerLabels">
            <div className="label">MD5 Hash Code: </div>
            <div className="labelData">{data.MD5HashCode}</div>
          </div>
        </div>
        <a
          href={data.DownloadUrl}
          rel="noopener noreferrer"
          target="_blank"
          className="ContainerTop"
        >
          <Pdf className="logoFile" />
          <div>Visualizar documento</div>
        </a>
      </div>
    );
  };
  const fileNoValid = () => {
    return (
      <div className="Container">
        <div
          className="ContainerTop"
          style={{flexDirection: "column"}}
        >
          <Failure className={"logoValidate"} />
          <div className="title">O arquivo não é válido, ou não consta em nossos registros.</div>
        </div>
      </div>
    );
  };
  // console.log(data);
  return <div>{data && data.DownloadUrl !== "" ? fileValid() : fileNoValid()} </div>;
};
