import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/home';

function Routas() {
    return (
        <Router>
            <Routes>
                <Route path="/:id?/:id1?/:id2?/:id3?/:id4?/:id5?/:id6?/:id7?/:id8?/:id9?/:id10?/:id11?/:id12?" element={<Home />} />
            </Routes>
        </Router>
    );
}

export default Routas;